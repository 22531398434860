import "./styles.css";
import React, { Fragment } from "react";

import { HttpRequest, HttpRequestForCSV } from "../../service/HttpRequest";

import Spinner from "../../component/spinner";
import OpenStreetMap from "../../component/insane-openstreet-map";

import InsaneCollapseContent from "../../component/insane-collapse-content";
import InsaneDatePicker from "../../component/insane-date-picker";

import iconSetting from "../../assets/icons/settings_40dp_FFF.png";
import iconMore from "../../assets/icons/more_vert_40dp_FFF.png";
import iconSearch from "../../assets/icons/search_40dp_FFF.png";

import { useGlobalContext } from "../../service/GlobalContext";

function App() {

  const [globalState, setGlobalState] = useGlobalContext();

  const [loading, setLoading] = React.useState(false);

  const [search_date, set_search_date] = React.useState("");
  const [search_startdate, set_search_startdate] = React.useState("");
  const [search_enddate, set_search_enddate] = React.useState("");


  const [search_detail, set_search_detail] = React.useState("");

  const [search_province, set_search_province] = React.useState("");

  const [fitBounds_MAP_ref, set_fitBounds_MAP_ref] = React.useState(null);

  const [legendLabel, setLegendLabel] = React.useState([]);
  const [PIN_MAP, SET_PIN_MAP] = React.useState([]);
  const [POLYGON_MAP, SET_POLYGON_MAP] = React.useState([]);
  const [POLYLINE_MAP, SET_POLYLINE_MAP] = React.useState([]);
  const [CIRCLE_MAP, SET_CIRCLE_MAP] = React.useState([]);
  const [CLUSTER_MAP, SET_CLUSTER_MAP] = React.useState([]);
  const [TRAFFIC_MAP, SET_TRAFFIC_MAP] = React.useState([]);

  const [ENUM, SET_ENUM] = React.useState(null);
  const [PROVINCES, SET_PROVINCES] = React.useState([]);

  const [DISPLAY_PIN, SET_DISPLAY_PIN] = React.useState(true);
  const [DISPLAY_ZONE, SET_DISPLAY_ZONE] = React.useState(true);
  const [DISPLAY_INCIDENT, SET_DISPLAY_INCIDENT] = React.useState(true);
  const [DISPLAY_LINK, SET_DISPLAY_LINK] = React.useState(true);

  const [DATA_SAFETY, SET_DATA_SAFETY] = React.useState(null);
  const [DATA_DASHBOARD, SET_DATA_DASHBOARD] = React.useState(null);

  const [FILTER_SAFETY_OPTION, SET_FILTER_SAFETY_OPTION] = React.useState([]);
  const [FILTER_SAFETY_SELECT, SET_FILTER_SAFETY_SELECT] = React.useState("speed");

  const [state_filter, set_state_filter] = React.useState(false);
  const [state_detail, set_state_detail] = React.useState(false);

  const [mapSettingDefault, setMapSettingDefault] = React.useState({
    center: [13.762201991756932, 100.50106010074637],
    zoom: 10,
    raduis_m: 1000
  });

  function downloadCSV(csvContent, fileName) {
    // console.log(csvContent);
    let encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link); // Required for FF

    link.click();

  }

  async function getSpeeding() {
    await HttpRequest("/api/getzone",
      {
        "zone_id": null,
        "allegation": "speeding",
        "date": search_date,
        "province_id": search_province,
        "index": null,
        "count": null,
        "search": search_detail
      }
    ).then((data) => {
      console.log("HttpRequest getSpeeding :", data);

      if (data.result === "OK") {
        let zones = data.zones;
        SET_DATA_SAFETY(zones);
      }
    });
  }

  async function downloadZonePinSpeeding() {
    // setLoading(true);
    await HttpRequestForCSV("/api/downloadzonepin",
      {
        "zone_id": null,
        "allegation": "speeding",
        "date": search_date,
        "province_id": search_province,
        "index:": null,
        "count": null,
        "search": search_detail
      }
    ).then((data) => {
      // console.log("HttpRequest downloadzonepin :", data);
      let fileName = ("ZonePinSpeeding_" + search_date + "_" + search_enddate);
      downloadCSV(data, fileName);
    });
  }

  async function getBan() {
    await HttpRequest("/api/getzone",
      {
        "zone_id": null,
        "allegation": "ban",
        "date": search_date,
        "province_id": search_province,
        "index:": null,
        "count": null,
        "search": search_detail
      }
    ).then((data) => {
      console.log("HttpRequest getBan :", data);

      if (data.result === "OK") {
        let zones = data.zones;
        SET_DATA_SAFETY(zones);
      }
    });
  }

  async function downloadZonePinBan() {
    // setLoading(true);
    await HttpRequestForCSV("/api/downloadzonepin",
      {
        "zone_id": null,
        "allegation": "ban",
        "date": search_date,
        "province_id": search_province,
        "index:": null,
        "count": null,
        "search": search_detail
      }
    ).then((data) => {
      // console.log("HttpRequest downloadzonepin :", data);
      let fileName = ("ZonePinBan_" + search_date + "_" + search_enddate);
      downloadCSV(data, fileName);
    });
  }

  async function getDashboard() {
    await HttpRequest("/api/getdashboard",
      {
        "zone_id": null,
        "date": search_date,
        "province_id": search_province,
      }
    ).then((data) => {
      console.log("HttpRequest getdashboard :", data);

      if (data.result === "OK") {
        let count = data.zones;
        SET_DATA_DASHBOARD(count);
      }
    });
  }

  function formatedate(date = new Date()) {
    let d = new Date(date);
    let dd = d.getDate();
    let mm = d.getMonth() + 1;
    let yy = d.getFullYear();

    function zerobefore(n) { return (n > 9) ? n : ("0" + n) }
    return yy + "-" + zerobefore(mm) + "-" + zerobefore(dd);
  }

  function formatedateTime(date = new Date()) {
    let d = new Date(date);
    let dd = d.getDate();
    let mm = d.getMonth() + 1;
    let yy = d.getFullYear();

    let H = d.getHours();
    let M = d.getMinutes();
    let S = d.getSeconds();

    function zerobefore(n) { return (n > 9) ? (n) : ("0" + n) }

    return yy + "/" + zerobefore(mm) + "/" + zerobefore(dd) + " " + zerobefore(H) + ":" + zerobefore(M) + ":" + zerobefore(S)
  }

  function getDisplayFilter(main_key, display_key, find_key, find_value) {
    // console.log(ENUM);
    if (ENUM) {
      let arr_filter = ENUM[main_key];
      if (arr_filter) {
        let result = arr_filter.filter((e) => e[find_key] === find_value);
        if (result.length > 0) {
          return result[0][display_key];
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  }

  function onPanMap(pin) {
    if (pin.lat && pin.lon) {
      set_fitBounds_MAP_ref([[pin.lat, pin.lon]]);
    }
  }

  async function onSearch() {

    setLoading(true);

    switch (FILTER_SAFETY_SELECT) {
      case 'speed':
        await getSpeeding();
        SET_DISPLAY_INCIDENT(false);
        SET_DISPLAY_LINK(false);
        break;
      case 'ban':
        await getBan();
        SET_DISPLAY_INCIDENT(false);
        SET_DISPLAY_LINK(false);
        break;

      default:
        break;
    }

    await getDashboard();

    setLoading(false);
  }

  function onDownloadSafety(id) {
    // console.log(id);
    switch (id) {
      case "ban":
        downloadZonePinBan();
        break;
      case "speed":
        downloadZonePinSpeeding();
        break;

      default:
        break;
    }
  }

  React.useEffect(() => {
    const controller = new AbortController();

    if (globalState.enum) {

      let current_date = formatedate();
      set_search_date(current_date);
      set_search_startdate("2024-01-01");
      set_search_enddate(current_date);

      let data_enum = { ...globalState.enum };
      SET_ENUM(data_enum);

      let new_state_option_map = {
        ...mapSettingDefault,
        center: [data_enum.default.center_lat, data_enum.default.canter_lon],
        raduis_m: data_enum.default.raduis_m
      }

      setLegendLabel(data_enum.condition_safety);

      setMapSettingDefault(new_state_option_map);
      SET_PROVINCES(data_enum.filter_province);

      if (data_enum.filter_province.length > 0) {
        set_search_province(data_enum.filter_province[0].id);
      }

      SET_FILTER_SAFETY_OPTION(data_enum.filter_safety);
    }

    return () => {
      controller.abort();
    };
  }, [globalState.enum]);


  React.useEffect(() => {
    const controller = new AbortController();

    if (ENUM) {
      onSearch();
    }

    return () => {
      controller.abort();
    };
  }, [ENUM, FILTER_SAFETY_SELECT]);

  React.useEffect(() => {
    const controller = new AbortController();

    SET_PIN_MAP([]);
    SET_POLYGON_MAP([]);
    SET_POLYLINE_MAP([]);
    SET_CIRCLE_MAP([]);

    if (DATA_SAFETY) {
      if (DISPLAY_ZONE) {

        let polygons = []

        for (let i = 0; i < DATA_SAFETY.length; i++) {

          const PL = DATA_SAFETY[i].links;
          const label = DATA_SAFETY[i].label;
          const name = DATA_SAFETY[i].name;

          let _polygons = [...PL];

          for (let j = 0; j < _polygons.length; j++) {
            _polygons[j] = { ..._polygons[j], label: label, name: name };
          }

          polygons = [...polygons, ..._polygons];

        }

        SET_POLYGON_MAP(polygons);
      }

      if (DISPLAY_PIN) {

        let key_pin = "";

        switch (FILTER_SAFETY_SELECT) {
          case "ban":
            key_pin = "ban_pins"
            break;
          case "speed":
            key_pin = "speeding_pins"
            break;
        }

        let pins = [];

        for (let i = 0; i < DATA_SAFETY.length; i++) {
          if (DATA_SAFETY[i][key_pin]) {
            const P = DATA_SAFETY[i][key_pin];
            pins = [...pins, ...P];
          }
        }

        SET_PIN_MAP(pins);
      }
    }


    return () => {
      controller.abort();
    };
  }, [DATA_SAFETY, DISPLAY_ZONE, DISPLAY_PIN, DISPLAY_INCIDENT, DISPLAY_LINK]);


  /////// Set Toggle popup Text Marker Popup ////////////////
  const [detailClick, setDetailClick] = React.useState(null);

  const togglePin = (pin) => {
    pin.markerRef = pin.markerRef || React.createRef();
    const marker = pin.markerRef.current;
    if (marker) {
      if (!pin.isVisible) {
        marker.openPopup();  // Open the popup
      } else {
        marker.closePopup();  // Close the popup
      }
      pin.isVisible = !pin.isVisible;  // Toggle the visibility state
    }
  };

  return (
    <>
      {loading ? (
        <div
          className="main-content"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Spinner></Spinner>
          <div style={{ color: "#000" }}>กำลังโหลด</div>
        </div>
      ) : (
        <div className="main-content">
          <div className="page-content">

            <InsaneCollapseContent title="Map option" type="filter" color="#005baf" style={{ zIndex: 20 }} state={state_filter} stateChange={set_state_filter}>

              <div className="filter-content">

                <div className="checkbox-display-container">
                  <div className="head-label">การแสดงผล</div>
                  {
                    FILTER_SAFETY_OPTION.map((option, index) => {
                      return (
                        (index === 0 || index === 1) ? (
                          <Fragment key={index}>
                            <label className="custom-checkmark checkbox">
                              <input type="checkbox" checked={FILTER_SAFETY_SELECT === option.id} onChange={() => SET_FILTER_SAFETY_SELECT(option.id)} />
                              <span className="checkmark"></span>
                              {option.title}
                            </label>
                            {
                              DATA_DASHBOARD ? (
                                <div className="summary">
                                  {DATA_DASHBOARD["total_" + option.id + "_vehicle"]} {DATA_DASHBOARD["total_" + option.id + "_vehicle_unit"]}
                                </div>
                              ) : (null)
                            }
                          </Fragment>
                        ) : (
                          null
                        )
                      );
                    })
                  }
                </div>

                <div style={{ flexGrow: 1 }}></div>

                {
                  ((legendLabel.length > 0) && DISPLAY_LINK) ? (
                    <div className="legend-label-container" style={{ marginBottom: 10 }}>
                      <div className="head-label">จำนวนอุบัติเหตุบนช่วงถนน</div>
                      {
                        legendLabel.map((label, index) => {
                          return (
                            <div className="legend" key={index}>
                              <div className="color" style={{
                                background: label.color ? label.color : "#444",
                                minHeight: label.size ? "unset" : 20,
                                height: label.size ? parseInt(label.size) : 20
                              }}></div>
                              <div className="text">{label.title}</div>
                            </div>
                          );
                        })
                      }
                    </div>
                  ) : (null)
                }

              </div>
            </InsaneCollapseContent>

            <InsaneCollapseContent title="DETAIL" type="detail" color="#34495E" style={{ zIndex: 19 }} state={state_detail} stateChange={set_state_detail}>
              <div className="detail-content">
                <div className="selection-container">
                  <div className="head-label">การแสดงผลย่อย</div>

                  <label className="custom-checkmark checkbox">
                    <input type="checkbox" checked={DISPLAY_ZONE} onChange={(event) => SET_DISPLAY_ZONE(event.target.checked)} />
                    <span className="checkmark"></span>
                    {
                      (FILTER_SAFETY_SELECT === "speed") ? (
                        <div className="label">เขตพื้นที่/เส้นทางที่ควบคุมความเร็วรถบรรทุก</div>
                      ) : (
                        <div className="label">เขตพื้นที่/เส้นทางที่ห้ามเข้าและจอด</div>
                      )
                    }
                  </label>

                  <label className="custom-checkmark checkbox">
                    <input type="checkbox" checked={DISPLAY_PIN} onChange={(event) => SET_DISPLAY_PIN(event.target.checked)} />
                    <span className="checkmark"></span>
                    <div className="label">ตำแหน่งที่ตรวจจับได้</div>
                  </label>
                </div>

                <div className="title" style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  <h4 style={{ fontWeight: "bolder" }}>รายการยานพาหนะ</h4>
                  <div style={{ flexGrow: 1 }}></div>
                  <button style={{ color: "#FFF", fontSize: 14, fontWeight: "bolder" }} onClick={() => onDownloadSafety(FILTER_SAFETY_SELECT)}>ดาวน์โหลด</button>
                </div>

                <div className="title" style={{ width: "100%", display: "flex", alignItems: "center", gap: 10 }}>
                  <input
                    type="text"
                    className="form-control"
                    style={{ flexGrow: 1 }}
                    value={search_detail}
                    onChange={(event) =>
                      set_search_detail(event.target.value)
                    }
                  />
                  <button style={{ color: "#FFF", fontSize: 14, fontWeight: "bolder" }} onClick={() => onSearch()}>ค้นหา</button>
                </div>

                <div className="content-table">

                  <div className="head-table" style={{ background: "#ccc" }}>
                    <div className="field">ประเภทรถ</div>
                    <div className="field">เวลา</div>
                    <div className="field">{FILTER_SAFETY_SELECT === "speed" ? "ความเร็ว" : "ระยะเวลา"}</div>
                  </div>

                  {
                    PIN_MAP.map((pin, index) => {
                      const isClickDetail = detailClick === pin;
                      return (
                        <Fragment key={index}>
                          <div className={"list-table toggle-web " + (isClickDetail ? 'active' : '')}
                            onClick={() => {
                              setDetailClick(pin);
                              onPanMap(pin);
                              togglePin(pin);  // Toggle the marker popup
                            }}
                          >
                            <div className="field">{pin.vehicle_type}</div>
                            <div className="field">{pin.day} {pin.time}</div>
                            <div className="field">{FILTER_SAFETY_SELECT === "speed" ? pin.speed_kph.toFixed(0) + " กม./ชม." : pin.stoptime_m + " นาที"}{ }</div>
                          </div>

                          <div className={"list-table toggle-mobile " + (isClickDetail ? 'active' : '')}
                            onClick={() => {
                              set_state_detail(false);
                              onPanMap(pin);
                              setDetailClick(pin);
                              togglePin(pin);  // Toggle the marker popup
                            }}
                          >
                            <div className="field">{pin.vehicle_type}</div>
                            <div className="field">{pin.day} {pin.time}</div>
                            <div className="field">{FILTER_SAFETY_SELECT === "speed" ? pin.speed_kph.toFixed(0) + " กม./ชม." : pin.stoptime_m + " นาที"}{ }</div>
                          </div>
                        </Fragment>
                      );
                    })
                  }
                </div>
              </div>
            </InsaneCollapseContent>

            <div className="content">

              <div className="content-map-header">

                <button className="bt-toggle" onClick={() => {
                  state_filter ? set_state_filter(false) : set_state_filter(true)
                }}>
                  <img src={iconSetting} />
                  <label>Map option</label>
                </button>

                <button className="bt-toggle" onClick={() => {
                  state_detail ? set_state_detail(false) : set_state_detail(true)
                }}>
                  <img src={iconMore} />
                  <label>Detail</label>
                </button>

                <div className="container-input">
                  <label>พื้นที่</label>
                  <select
                    className="form-control"
                    value={search_province}
                    onChange={(event) => set_search_province(event.target.value)}
                  >
                    {PROVINCES.map((option, index) => {
                      return (<option key={index} value={option.id}>{option.title}</option>);
                    })}
                  </select>
                </div>

                <div className="container-input">
                  <label>ตั้งแต่วันที่</label>

                  <input
                    type="date"
                    className="form-control input-date-mobile"
                    value={search_date}
                    onChange={(event) => set_search_date(event.target.value)}
                  />

                  <InsaneDatePicker
                    type="date"
                    className="form-control input-date-web"
                    value={search_date}
                    change={(event) => set_search_date(event)}
                  />

                </div>

                <button className="bt-search web " onClick={() => onSearch()}>
                  <img src={iconSearch} />
                  <label>ค้นหา</label>
                </button>

                <div className="web" style={{ flexGrow: 1 }}></div>

                <button className="bt-toggle" onClick={() => onSearch()}>
                  <img src={iconSearch} />
                  <label>ค้นหา</label>
                </button>

              </div>

              <OpenStreetMap
                pinmap={PIN_MAP}
                polygon={POLYGON_MAP}
                polyline={POLYLINE_MAP}
                traffic={TRAFFIC_MAP}
                circle={CIRCLE_MAP}
                cluster={CLUSTER_MAP}
                fitBoundsRef={fitBounds_MAP_ref}
                center={mapSettingDefault.center}
                zoom={mapSettingDefault.zoom}
              // togglePin={togglePin}  // Pass the toggle function here
              />

            </div>

            <div className="content-mobile"></div>

          </div>
        </div>
      )}
    </>
  );
}

export default App;
