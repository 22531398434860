import "./styles.css";
import React, { Fragment } from "react";

import { HttpRequest, HttpRequestForCSVByURL } from "../../service/HttpRequest";
// import { useGlobalContext } from "../../service/GlobalContext";

import Spinner from "../../component/spinner";

function App() {

  // const [globalState, setGlobalState] = useGlobalContext();

  const [loading, setLoading] = React.useState(false);
  const [DATA_REPORT, SET_DATA_REPORT] = React.useState(null);

  const getReportList = async (signal) => {
    setLoading(true);

    const data = await HttpRequest("/api/getreportlist", {
      "o_place_id": "P50",
      "d_place_id": "D7",
      "date": "2024-08-09",
      "peak_id": "ALLDAY",
      "taz": true,
      "index:": null,
      "count": null,
      "vehicle_category": "truck"
    }, { signal });

    if (data.result === "OK") {
      console.log("getReportList : ", data);
      SET_DATA_REPORT(data);
    }

    setLoading(false);
  };


  function downloadCSV(csvContent, fileName) {
    //console.log(csvContent);
    let encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  const onDownload = async (url, body, month, type, province) => {
    console.log("url : ", url);
    console.log("body : ", body);
    await HttpRequestForCSVByURL(url, body).then((data) => {
      // console.log("HttpRequest downloadmostlink :", data);
      let fileName = ("รายงาน" + type + " ประจำเดือน " + month + " เขตพื้นที่ " + province);
      downloadCSV(data, fileName);
    });
  }

  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      await getReportList(signal);
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, []);


  return (
    <>
      {loading ? (
        <div
          className="main-content"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <div className="page-content bg-image">
            <Spinner></Spinner>
            <div style={{ color: "#FFF" }}>กำลังโหลด</div>
          </div>
        </div>
      ) : (
        <div className="main-content">
          <div className="page-content bg-image">
            <div className="content-report-list">
              <div className="content-title">
                <div className="t">รายงานประจำเดือน</div>
                <div style={{ flexGrow: 1 }}></div>
                {/* <button className="bt add" onClick={() => SET_STATE_PAGE("adduser")}>+ Add User</button> */}
              </div>

              <div className="content-table">
                {
                  DATA_REPORT ? (
                    <table>
                      <thead>
                        <tr>
                          <th rowSpan={2}>{DATA_REPORT.header.month}</th>
                          <th rowSpan={2}>{DATA_REPORT.header.type}</th>
                          <th colSpan={(Object.keys(DATA_REPORT.header.provinces)).length}>พื้นที่</th>
                        </tr>
                        <tr>
                          {
                            (Object.keys(DATA_REPORT.header.provinces)).map((key, i) => {
                              return <th key={i}>{DATA_REPORT.header.provinces[key]}</th>
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          DATA_REPORT.reportlist.map((report, i) => {
                            return <Fragment key={i}>
                              {
                                report.types.map((type, j) => {
                                  return (
                                    <tr key={j}>
                                      {(j === 0) ? (<td rowSpan={report.types.length}>{report.month}</td>) : (null)}
                                      <td>{type.type}</td>
                                      {
                                        (Object.keys(DATA_REPORT.header.provinces)).map((key, k) => {
                                          let url = type.provinces[key].url;
                                          let body = type.provinces[key].body;
                                          return <td key={k}>
                                            <div className="bt-group">
                                              <button
                                                className="bt edit"
                                                onClick={() => onDownload(url, body, report.month, type.type, DATA_REPORT.header.provinces[key])}>
                                                Download
                                              </button>
                                            </div>
                                          </td>
                                        })
                                      }
                                    </tr>
                                  )
                                })
                              }
                            </Fragment>
                          })
                        }
                      </tbody>
                    </table>
                  ) : (
                    <span>Please wait...</span>
                  )
                }

              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
