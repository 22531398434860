import "./styles.css";
import React, { useEffect } from "react"; //new

import { HttpRequest, HttpRequestForCSV } from "../../service/HttpRequest";
import { useGlobalContext } from "../../service/GlobalContext";

import OpenStreetMap from "../../component/insane-openstreet-map";
import ChartBar from "../../component/chart-bar";
import Spinner from "../../component/spinner";

import iconPinPort from "../../assets/icons/transport - port.png";
import iconPinAirport from "../../assets/icons/transport - airport.png";
import iconPinBus from "../../assets/icons/transport - bus.png";
import iconPinIndustrial from "../../assets/icons/transport - industrial.png";
import iconPinTrain from "../../assets/icons/transport - train.png";

import iconRadar from "../../assets/icons/sensor.png";
import iconCCTV from "../../assets/icons/cctv.png";
import iconBluetooth from "../../assets/icons/bluetooth.png";


import WKX from "wkx";

// import iconPinIntersect from "../../assets/icons/pin-intersec.png";
// import iconPinIntersectLight from "../../assets/icons/pin-intersec-trafficlight.png";

// import IconDownload from "../../assets/icons/download-icon.png";


function App() {


  const [globalState, setGlobalState] = useGlobalContext();

  const [loadingMap, setLoadingMap] = React.useState(true);
  const [loadingChartTraffic, setLoadingChartTraffic] = React.useState(true);
  const [loadingChartIntersect, setLoadingChartIntersect] = React.useState(true);

  const [search_date, set_search_date] = React.useState("");
  const [search_time, set_search_time] = React.useState("ALLDAY");
  const [search_province, set_search_province] = React.useState("");
  const [search_month, set_search_month] = React.useState("1M");
  const [search_dayofweek, set_search_dayofweek] = React.useState("ALLDAY");
  const [search_frequentjam_percentile, set_search_frequentjam_percentile] = React.useState("P50");

  const [legendLabel, setLegendLabel] = React.useState([]);

  const [ENUM, SET_ENUM] = React.useState(null);
  const [PEAKOPTION, SET_PEAKOPTION] = React.useState([]);
  const [PROVINCES, SET_PROVINCES] = React.useState([]);
  const [PERIODOPTION, SET_PERIODOPTION] = React.useState([]);
  const [DAYOFWEEKOPTION, SET_DAYOFWEEKOPTION] = React.useState([]);
  const [FREQUENTJAMPERCENTILEOPTION, SET_FREQUENTJAMPERCENTILEOPTION] = React.useState([]);

  const [PIN_INTERSECT_MAP, SET_PIN_INTERSECT_MAP] = React.useState([]);

  const [PIN_MAP, SET_PIN_MAP] = React.useState([]);
  const [POLYGON_MAP, SET_POLYGON_MAP] = React.useState([]);
  const [TRAFFIC_MAP, SET_TRAFFIC_MAP] = React.useState([]);

  const [DATA_FREQUENT_JAM, SET_DATA_FREQUENT_JAM] = React.useState(null);

  const [DATA_MOST_TRAFFIC, SET_DATA_MOST_TRAFFIC] = React.useState(null);
  const [DATA_MOST_INTERSECT, SET_DATA_MOST_INTERSECT] = React.useState(null);

  const [mapSettingDefault, setMapSettingDefault] = React.useState({
    center: [13.762201991756932, 100.50106010074637],
    zoom: 10,
    raduis_m: 1000
  });
  const [fitBounds_MAP_ref, set_fitBounds_MAP_ref] = React.useState(null);

  const [DATA_CHART_TRAFFIC, SET_DATA_CHART_TRAFFIC] = React.useState(null);
  const [DATA_CHART_INTERSECT, SET_DATA_CHART_INTERSECT] = React.useState(null);

  const [OPTIONS_SENSOR_TYPE, SET_OPTIONS_SENSOR_TYPE] = React.useState([]);

  const [DATA_PLACE, SET_DATA_PLACE] = React.useState(null);
  const [DATA_SENSOR, SET_DATA_SENSOR] = React.useState(null);

  const [DATA_MAP_INDUSTRIAL, SET_DATA_MAP_INDUSTRIAL] = React.useState([]);
  const [DATA_MAP_PORT, SET_DATA_MAP_PORT] = React.useState([]);
  const [DATA_MAP_AIRPORT, SET_DATA_MAP_AIRPORT] = React.useState([]);
  const [DATA_MAP_BUS, SET_DATA_MAP_BUS] = React.useState([]);
  const [DATA_MAP_TRAIN, SET_DATA_MAP_TRAIN] = React.useState([]);

  const [DISPLAY_MAP_INDUSTRIAL, SET_DISPLAY_MAP_INDUSTRIAL] = React.useState(false);
  const [DISPLAY_MAP_PORT, SET_DISPLAY_MAP_PORT] = React.useState(false);
  const [DISPLAY_MAP_AIRPORT, SET_DISPLAY_MAP_AIRPORT] = React.useState(false);
  const [DISPLAY_MAP_BUS, SET_DISPLAY_MAP_BUS] = React.useState(false);
  const [DISPLAY_MAP_TRAIN, SET_DISPLAY_MAP_TRAIN] = React.useState(false);


  const [RANGE_TIME_DATA, SET_RANGE_TIME_DATA] = React.useState(null);

  const [STATE_CONTENT_CHART, SET_STATE_CONTENT_CHART] = React.useState(null);

  const [STATE_LEGEND_MOBILE, SET_STATE_LEGEND_MOBILE] = React.useState(false);



  async function getFrequentJam() {

    await HttpRequest("/api/getfrequentjam",

      {
        "date": search_date,
        "peak_id": search_time,
        "period_id": search_month,
        "province_id": search_province,
        "dayofweek_id": search_dayofweek,
        "percentile_id": search_frequentjam_percentile,
        "count": 15,
        "wkb": true
      }
    ).then((data) => {
      console.log("HttpRequest getfrequentjam :", data);
      if (data.result === "OK") {


        SET_DATA_FREQUENT_JAM(data);


        let most5roads = [...data.most5roads];

        for (let i = 0; i < most5roads.length; i++) {

          let road = most5roads[i];
          let decode = Buffer.from(road.wkb, 'base64');
          road.geojson = WKX.Geometry.parseTwkb(decode).toGeoJSON();

        }

        SET_DATA_MOST_INTERSECT(data.most5intersects);
        SET_DATA_MOST_TRAFFIC(most5roads);
        SET_RANGE_TIME_DATA(data.range_data);
      }
    });
  }

  // ส่วนที่ 1-1

  async function getPlace() {
    await HttpRequest("/api/getplace",
      {
        "search": "",
        "province_id": search_province,
        "index:": 0,
        "count": 0
      }
    ).then((data) => {
      console.log("HttpRequest getplace :", data);
      if (data.result === "OK") {
        let places = data.places;

        let industrialestate = [];
        let airport = [];
        let port = [];
        let bus = [];
        let train = [];

        for (let i = 0; i < places.length; i++) {
          const place = places[i];
          switch (place.place_type) {
            case "industrialestate":
              industrialestate.push(place);
              break;
            case "airport":
              airport.push(place);
              break;
            case "port":
              port.push(place);
              break;
            case "bus":
              bus.push(place);
              break;
            case "train":
              train.push(place);
              break;

            default:
              break;
          }
        }

        SET_DATA_MAP_INDUSTRIAL(industrialestate);
        SET_DATA_MAP_AIRPORT(airport);
        SET_DATA_MAP_PORT(port);
        SET_DATA_MAP_BUS(bus);
        SET_DATA_MAP_TRAIN(train);

        SET_DATA_PLACE(places);

      } else {

      }
    });
  }


  //ส่วนที่ 1-2
  async function getSensor() {

    await HttpRequest("/api/getsensor",
      {
        "sensor_id": null,
        "date": search_date,
        "peak_id": search_time,
        "province_id": search_province,
        "index:": null,
        "count": null
      }
    ).then((data) => {
      console.log("HttpRequest getsensor :", data);
      if (data.result === "OK") {
        SET_DATA_SENSOR(data.sensors);
      }
    });
  }

  function formatedate(date = new Date()) {

    let d = new Date(date);
    let dd = d.getDate();
    let mm = d.getMonth() + 1;
    let yy = d.getFullYear();

    function zerobefore(n) { return (n > 9) ? (n) : ("0" + n) }

    return yy + "-" + zerobefore(mm) + "-" + zerobefore(dd);

  }

  function formatedate_full(date = new Date()) {

    var months_th = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
    // var months_th_mini = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];

    let d = new Date(date);
    let dd = d.getDate();
    let mm = d.getMonth();
    let yy = d.getFullYear() + 543;

    return dd + " " + months_th[mm] + " " + yy;

  }

  function toggleContentChart(name) {
    if (name === STATE_CONTENT_CHART) {
      SET_STATE_CONTENT_CHART(null);
    } else {
      SET_STATE_CONTENT_CHART(name)
    }
  }


  function downloadCSV(csvContent, fileName) {
    let encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  function onToggleLegendMobile() {
    if (STATE_LEGEND_MOBILE) {
      SET_STATE_LEGEND_MOBILE(false);
    } else {
      SET_STATE_LEGEND_MOBILE(true);
    }
  }


  async function downloadFrequentJamLink() {
    await HttpRequestForCSV("/api/downloadfrequentjamlink",

      {
        "date": search_date,
        "peak_id": search_time,
        "period_id": search_month,
        "province_id": search_province,
        "dayofweek_id": search_dayofweek,
        "percentile_id": search_frequentjam_percentile,
        //"count": 15,
        //"wkb": true
      }
    ).then((data) => {
      // console.log("HttpRequest downloadmostlink :", data);
      let fileName = ("FrequentJamLink_" + "2024-09-15" + "_" + "ALLDAY");
      downloadCSV(data, fileName);
    });
  }


  // ส่วนที่ 2-2
  async function downloadFrequentJamIntersect() {
    await HttpRequestForCSV("/api/downloadfrequentjamintersect",
      {
        "date": search_date,
        "peak_id": search_time,
        "period_id": search_month,
        "province_id": search_province,
        "dayofweek_id": search_dayofweek,
        "percentile_id": search_frequentjam_percentile,
      }
    ).then((data) => {
      // console.log("HttpRequest downloadmostlink :", data);
      let fileName = ("FrequentJamIntersect_" + "2024-09-15" + "_" + "ALLDAY");
      downloadCSV(data, fileName);
    });
  }

  React.useEffect(() => {
    const controller = new AbortController();

    let current_date = formatedate();
    set_search_date(current_date);

    return () => {
      controller.abort();
    };
  }, []);

  // 2

  React.useEffect(() => {
    const controller = new AbortController();

    if (globalState.enum) {

      let data_enum = { ...globalState.enum }

      SET_ENUM(data_enum);

      let new_state_option_map = {
        ...mapSettingDefault,
        center: [data_enum.default.center_lat, data_enum.default.canter_lon],
        raduis_m: data_enum.default.raduis_m
      }

      setMapSettingDefault(new_state_option_map);

      for (let i = 0; i < data_enum.filter_traffic.length; i++) {
        data_enum.filter_traffic[i].lists = [];
      }

      // SET_FILTER_TRAFFIC_OPTION(data_enum.filter_traffic);

      SET_PEAKOPTION(data_enum.peak_dashboard);
      SET_PROVINCES(data_enum.filter_province); // show province in option bar

      SET_PERIODOPTION(data_enum.period_dashboard);
      SET_DAYOFWEEKOPTION(data_enum.dayofweek);
      SET_FREQUENTJAMPERCENTILEOPTION(data_enum.frequentjam_percentile);

      if (data_enum.filter_province.length > 0) {
        set_search_province(data_enum.filter_province[0].id);
      }

      setLegendLabel(data_enum.condition_traffic);

      SET_OPTIONS_SENSOR_TYPE(data_enum.sensor_type);

    }

    return () => {
      controller.abort();
    };
  }, [globalState]);


  React.useEffect(() => {
    const controller = new AbortController();

    setLoadingMap(true);
    setLoadingChartTraffic(true);
    setLoadingChartIntersect(true);

    SET_TRAFFIC_MAP([]);
    SET_POLYGON_MAP([]);
    SET_PIN_MAP([]);

    async function init() {

      await getFrequentJam();
      await getPlace(); // ส่วนที่ 1-1
      await getSensor(); // ส่วนที่ 1-2
      //await getTraffic();
    }

    if (ENUM) {
      init();
    }

    return () => {
      controller.abort();
    };
  }, [search_province, search_time, search_month, search_dayofweek, search_frequentjam_percentile]);


  React.useEffect(() => {

    const controller = new AbortController();

    // if (DATA_TRAFFIC) {
    if (DATA_MOST_TRAFFIC) {
      let polyline = [...DATA_MOST_TRAFFIC];

      for (let i = 0; i < polyline.length; i++) {
        polyline[i].color = "#999";
        let status = ENUM.condition_traffic.filter((legend) => polyline[i].status === legend.status);
        polyline[i].type = "traffic";
        if (status.length > 0) {
          if (polyline[i].focus) {
            // console.log(polyline[i]);
            polyline[i].color = "#46e2fa";
          } else {
            polyline[i].color = status[0].color;
          }
        }
      }

      setLoadingMap(false);
      SET_TRAFFIC_MAP(polyline);

    } else {
      setTimeout(() => {
        SET_TRAFFIC_MAP([]);
      }, 1000);
    }
    // }

    return () => {
      controller.abort();
    };
  }, [DATA_MOST_TRAFFIC]);


  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_MOST_TRAFFIC) {

      setLoadingChartTraffic(true);

      let data = [['ถนน', 'ร้อยละความติดขัด']];

      for (let i = 0; i < DATA_MOST_TRAFFIC.length; i++) {
        let road = DATA_MOST_TRAFFIC[i].label;
        let frequent = parseFloat((DATA_MOST_TRAFFIC[i].frequent).toFixed(1));

        data.push([road, frequent]);
      }

      SET_DATA_CHART_TRAFFIC(data);

      setLoadingChartTraffic(false);
    }

    return () => {
      controller.abort();
    };
  }, [DATA_MOST_TRAFFIC]);


  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_MOST_INTERSECT) {

      setLoadingChartIntersect(true);

      let data = [['ทางแยก', 'ร้อยละความติดขัด']];

      for (let i = 0; i < DATA_MOST_INTERSECT.length; i++) {
        let road = DATA_MOST_INTERSECT[i].label;
        let frequent = parseFloat((DATA_MOST_INTERSECT[i].frequent).toFixed(1));

        data.push([road, frequent]);
      }

      SET_DATA_CHART_INTERSECT(data);

      setLoadingChartIntersect(false);
    }

    return () => {
      controller.abort();
    };
  }, [DATA_MOST_INTERSECT]);


  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_MOST_INTERSECT) {

      setLoadingMap(true);

      let pins_intersect = [...DATA_MOST_INTERSECT];

      for (let i = 0; i < DATA_MOST_INTERSECT.length; i++) {

        if (pins_intersect[i].trafficlight > 0) {
          pins_intersect[i].pin_type = "intersect_trafficlight";
        } else {
          pins_intersect[i].pin_type = "intersect";
        }

        pins_intersect[i].color = "#999";

        let status = ENUM.condition_intersect.filter((legend) => pins_intersect[i].status === legend.status);
        if (status.length > 0) {
          pins_intersect[i].color = status[0].color;
        }

      }

      setLoadingMap(false);
      set_fitBounds_MAP_ref(pins_intersect);

      SET_PIN_INTERSECT_MAP(pins_intersect);
      SET_PIN_MAP(pins_intersect);
    }

    return () => {
      controller.abort();
    };
  }, [DATA_MOST_INTERSECT]);


  React.useEffect(() => {
    // Clear pins and polygons when switching screens or pages
    SET_PIN_MAP([]);
    SET_POLYGON_MAP([]);

    let isDataSelected = false; // Flag to track if data is selected

    // Check if necessary data is present before processing
    if (DATA_PLACE) {
      let pins = [...PIN_INTERSECT_MAP];
      let polygons = [];

      // Handle Sensor Data based on Checkbox Selection
      if (DATA_SENSOR) {
        OPTIONS_SENSOR_TYPE.forEach((sensorType) => {
          if (sensorType.checked) {
            isDataSelected = true; // Mark as selected if any sensorType is checked

            let filteredSensors = DATA_SENSOR.filter(
              (sensor) => sensor.sensor_type === sensorType.id
            );
            filteredSensors.forEach((sensor) => {
              sensor.pin_type = sensorType.id;
            });
            pins = [...pins, ...filteredSensors];
          }
        });
      }

      // Process other data sets (Industrial, Port, Airport, Bus, Train)
      if (DISPLAY_MAP_INDUSTRIAL && DATA_MAP_INDUSTRIAL) {
        isDataSelected = true;

        let pins_industrial = DATA_MAP_INDUSTRIAL.map((industrial) => {
          industrial.pin_type = "industrialestate";
          return industrial;
        });
        pins = [...pins, ...pins_industrial];
        polygons = [...polygons, ...pins_industrial];
      }

      if (DISPLAY_MAP_PORT && DATA_MAP_PORT) {
        isDataSelected = true;

        let pins_port = DATA_MAP_PORT.map((port) => {
          port.pin_type = "port";
          return port;
        });
        pins = [...pins, ...pins_port];
        polygons = [...polygons, ...pins_port];
      }

      if (DISPLAY_MAP_AIRPORT && DATA_MAP_AIRPORT) {
        isDataSelected = true;

        let pins_airport = DATA_MAP_AIRPORT.map((airport) => {
          airport.pin_type = "airport";
          return airport;
        });
        pins = [...pins, ...pins_airport];
        polygons = [...polygons, ...pins_airport];
      }

      if (DISPLAY_MAP_BUS && DATA_MAP_BUS) {
        isDataSelected = true;

        let pins_bus = DATA_MAP_BUS.map((bus) => {
          bus.pin_type = "bus";
          return bus;
        });
        pins = [...pins, ...pins_bus];
        polygons = [...polygons, ...pins_bus];
      }

      if (DISPLAY_MAP_TRAIN && DATA_MAP_TRAIN) {
        isDataSelected = true;

        let pins_train = DATA_MAP_TRAIN.map((train) => {
          train.pin_type = "train";
          return train;
        });
        pins = [...pins, ...pins_train];
      }

      // Update the state with pins and polygons
      SET_PIN_MAP(pins);
      SET_POLYGON_MAP(polygons);
    }

  }, [
    DATA_PLACE,
    PIN_INTERSECT_MAP,
    OPTIONS_SENSOR_TYPE,
    DATA_SENSOR,
    DISPLAY_MAP_INDUSTRIAL,
    DATA_MAP_INDUSTRIAL,
    DISPLAY_MAP_PORT,
    DATA_MAP_PORT,
    DISPLAY_MAP_AIRPORT,
    DATA_MAP_AIRPORT,
    DISPLAY_MAP_BUS,
    DATA_MAP_BUS,
    DISPLAY_MAP_TRAIN,
    DATA_MAP_TRAIN,
  ]);


  return (
    <div className="content-tab">
      <div className="content-map">
        <div className="content-map-header">

          <div className="container-input">
            <label>พื้นที่</label>

            {/*ส่วนที่ 3 */}
            <select
              value={search_province}
              onChange={(event) => set_search_province(event.target.value)}
            >
              {PROVINCES.map((option, index) => {
                return (<option key={index} value={option.id}>{option.title}</option>);
              })}
              {

              }
            </select>
          </div>

          <div className="container-input">
            <label>เวลา</label>
            <select
              value={search_time}
              onChange={(event) => set_search_time(event.target.value)}
            >
              {PEAKOPTION.map((option, index) => {
                return (<option key={index} value={option.id}>{option.title}</option>);
              })}
            </select>
          </div>

          <span className="container-input">
            <label>ช่วงเวลา</label>
            <select
              value={search_month}
              onChange={(event) => set_search_month(event.target.value)}>
              {PERIODOPTION.map((option, index) => {
                return (<option key={index} value={option.id}>{option.title}</option>);
              })}
            </select>
          </span>

          <span className="container-input">
            <label>ประเภทของวัน</label>
            <select
              value={search_dayofweek}
              onChange={(event) => set_search_dayofweek(event.target.value)}>
              {DAYOFWEEKOPTION.map((option, index) => {
                return (<option key={index} value={option.id}>{option.title}</option>);
              })}
            </select>
          </span>

          <span className="container-input">
            <label>Percentile ติดขัดประจำ</label>
            <select
              value={search_frequentjam_percentile}
              onChange={(event) => set_search_frequentjam_percentile(event.target.value)}>
              {FREQUENTJAMPERCENTILEOPTION.map((option, index) => {
                return (<option key={index} value={option.id}>{option.title}</option>);
              })}
            </select>
          </span>

          <div className="web" style={{ flexGrow: 1 }}></div>

          {/* {
            RANGE_TIME_DATA ? (
              <div className="date">
                <span>{RANGE_TIME_DATA.last_date}</span>
                <span style={{ fontSize: 14 }}>ข้อมูลเมื่อเวลา {RANGE_TIME_DATA.last_time}</span>
              </div>
            ) : (
              <Spinner></Spinner>
            )
          } */}

        </div>

        <div className="content-map-content">
          {
            loadingMap ? (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                <Spinner></Spinner>
                <div style={{ color: "#000" }}>กำลังโหลด</div>
              </div>
            ) : (
              <OpenStreetMap
                traffic={TRAFFIC_MAP}
                pinmap={PIN_MAP}
                polygon={POLYGON_MAP}
                center={mapSettingDefault.center}
                zoom={mapSettingDefault.zoom}
                fitBoundsRef={fitBounds_MAP_ref}
              />
            )
          }

          <div className={"overlay-map-detail " + (STATE_LEGEND_MOBILE ? "active" : "")} onClick={() => onToggleLegendMobile()}>
            <div className="overlay-map-detail-head">Map Option</div>
            <div className="overlay-map-detail-content">
              <div className="checkbox-display-container tab23">
                <div className="head-label web">โครงสร้างพื้นฐานคมนาคม</div>

                <label className="custom-checkmark checkbox">
                  <input type="checkbox" checked={DISPLAY_MAP_PORT} onChange={(event) => SET_DISPLAY_MAP_PORT(event.target.checked)} />
                  <span className="checkmark"></span>
                  <img className="icon" src={iconPinPort} />
                  <div className="label">ท่าเรือ</div>
                </label>

                <label className="custom-checkmark checkbox">
                  <input type="checkbox" checked={DISPLAY_MAP_INDUSTRIAL} onChange={(event) => SET_DISPLAY_MAP_INDUSTRIAL(event.target.checked)} />
                  <span className="checkmark"></span>
                  <img className="icon" src={iconPinIndustrial} />
                  <div className="label">นิคมอุตสาหกรรม</div>
                </label>

                <label className="custom-checkmark checkbox">
                  <input type="checkbox" checked={DISPLAY_MAP_AIRPORT} onChange={(event) => SET_DISPLAY_MAP_AIRPORT(event.target.checked)} />
                  <span className="checkmark"></span>
                  <img className="icon" src={iconPinAirport} />
                  <div className="label">ท่าอากาศยาน</div>
                </label>

                <label className="custom-checkmark checkbox">
                  <input type="checkbox" checked={DISPLAY_MAP_BUS} onChange={(event) => SET_DISPLAY_MAP_BUS(event.target.checked)} />
                  <span className="checkmark"></span>
                  <img className="icon" src={iconPinBus} />
                  <div className="label">บขส.</div>
                </label>

                <label className="custom-checkmark checkbox">
                  <input type="checkbox" checked={DISPLAY_MAP_TRAIN} onChange={(event) => SET_DISPLAY_MAP_TRAIN(event.target.checked)} />
                  <span className="checkmark"></span>
                  <img className="icon" src={iconPinTrain} />
                  <div className="label">รถไฟ</div>
                </label>
              </div>

              <div className="checkbox-display-container tab23">
                <div className="head-label web">แหล่งข้อมูล</div>

                {
                  OPTIONS_SENSOR_TYPE.map((sendor_type, index) => {
                    return (
                      <label className="custom-checkmark checkbox" key={index}>
                        <input type="checkbox"
                          checked={sendor_type.checked}
                          onChange={(event) => {
                            let new_state = [...OPTIONS_SENSOR_TYPE];
                            new_state[index].checked = event.target.checked;
                            SET_OPTIONS_SENSOR_TYPE(new_state);
                          }} />

                        {/*control icon for selected menu*/}
                        <span className="checkmark"></span>
                        {
                          (sendor_type.id === "radar") ? (<img className="icon" src={iconRadar} />) : //**  radio show icon left menu */
                            (sendor_type.id === "cctv") ? (<img className="icon" src={iconCCTV} />) :
                              (sendor_type.id === "bluetooth") ? (<img className="icon" src={iconBluetooth} />) : (null)
                        }

                        <div className="label">{sendor_type.title}</div> {/** name icon left menu by icon */}
                      </label>
                    );
                  })
                }
              </div>

              <div className="legend-map">
                <div className="legend">
                  {/* <img className="icon" width={18} height={18} src={iconPinIntersectLight} /> */}
                  <div className="icon">
                    <div style={{ background: "red", width: 20, height: 5, border: "1px solid #000" }}></div>
                  </div>
                  <div className="text">ช่วงถนนที่ติดขัดประจำ</div>
                </div>
                <div className="legend">
                  <div className="icon">
                    <div style={{ background: "red", width: 20, height: 20, border: "1px solid #000", borderRadius: "50%" }}></div>
                  </div>
                  <div className="text">ทางแยกมีสัญญาณไฟจราจร <br /> ที่ติดขัดประจำ</div>
                </div>
                <div className="legend">
                  <div className="icon">
                    <div style={{ background: "red", width: 20, height: 20, border: "1px solid #000" }}></div>
                  </div>
                  <div className="text">ทางแยกไม่มีสัญญาณไฟจราจร <br /> ที่ติดขัดประจำ</div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div className={"content-detail tab23 tab23-1 " + (STATE_CONTENT_CHART === "traffic" ? "active" : "")}>

        <div className="chart-title" onClick={() => toggleContentChart("traffic")}>
          ช่วงถนนที่การจราจรติดขัดประจำ
          <div className="chart-title-download"> {STATE_CONTENT_CHART === "traffic" ? "▼" : "▲"} </div>
        </div>

        <div className="button-download" onClick={() => downloadFrequentJamLink()}>ดาวน์โหลด</div>

        <div className="chart">
          <div className="bar-chart" style={{ background: "#FFF" }}>
            {
              loadingChartTraffic ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                  <Spinner></Spinner>
                  <div style={{ color: "#000" }}>กำลังโหลด</div>
                </div>
              ) : (
                <ChartBar
                  data={DATA_CHART_TRAFFIC} //กราฟ ช่วงถนนที่การจราจรติดขัด 15 อันดับแรก
                  options={{
                    legend: { position: 'none' },
                    tooltip: { textStyle: { fontSize: 12 } },
                    hAxis: {
                      title: "ร้อยละความติดขัด", minValue: 0, 
                      textStyle: { fontSize: 14 },
                      titleTextStyle: { fontSize: 14 },
                    },
                    height: (DATA_CHART_TRAFFIC.length * 20),
                    vAxis: { textStyle: { fontSize: 12 } },
                    chartArea: { width: "50%", left: 120, top: 5, right: 40, bottom: 60 },
                  }}
                  chartEvents={
                    [{
                      eventName: "select",
                      callback({ chartWrapper }) {
                        let selected = chartWrapper.getChart().getSelection();
                        if (selected.length > 0) {

                          let data_selected = DATA_MOST_TRAFFIC[(selected[0].row)];
                          // console.log(data_selected);
                          let coordinate = data_selected.geojson.coordinates;
                          for (let i = 0; i < coordinate.length; i++) {
                            coordinate[i].reverse();
                          }
                          set_fitBounds_MAP_ref(coordinate);

                        } else {
                          set_fitBounds_MAP_ref([]);
                        }
                      }
                    }]
                  }
                />
              )
            }

          </div>
        </div>
      </div>

      <div className={"content-detail tab23 tab23-2 " + (STATE_CONTENT_CHART === "intersect" ? "active" : "")}>

        <div className="chart-title" onClick={() => toggleContentChart("intersect")}>
          ช่วงทางแยกที่การจราจรติดขัดประจำ
          <div className="chart-title-download"> {STATE_CONTENT_CHART === "intersect" ? "▼" : "▲"} </div>
        </div>

        <div className="button-download" onClick={() => downloadFrequentJamIntersect()}>ดาวน์โหลด</div>

        <div className="chart">
          <div className="bar-chart" style={{ background: "#FFF" }}>
            {
              loadingChartIntersect ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                  <Spinner></Spinner>
                  <div style={{ color: "#000" }}>กำลังโหลด</div>
                </div>
              ) : (
                <ChartBar
                  data={DATA_CHART_INTERSECT} //กราฟ ช่วงถนนที่การจราจรติดขัดประจำ 15 อันดับแรก
                  options={{
                    legend: { position: 'none' },
                    tooltip: { textStyle: { fontSize: 12 } },
                    hAxis: {
                      title: "ร้อยละความติดขัด", minValue: 0,
                      textStyle: { fontSize: 14 },
                      titleTextStyle: { fontSize: 14 },
                    },
                    height: (DATA_MOST_INTERSECT.length * 20),
                    vAxis: { textStyle: { fontSize: 12 } },
                    chartArea: { width: "50%", left: 120, top: 5, right: 40, bottom: 60 },
                  }}
                  chartEvents={
                    [{
                      eventName: "select",
                      callback({ chartWrapper }) {
                        let selected = chartWrapper.getChart().getSelection();
                        if (selected.length > 0) {
                          set_fitBounds_MAP_ref([DATA_MOST_INTERSECT[(selected[0].row)]]);
                        } else {
                          set_fitBounds_MAP_ref([]);
                        }
                      }
                    }]
                  }
                />
              )
            }
          </div>


        </div>
      </div>
    </div>
  );
}

export default App;
