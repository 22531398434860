// import React from 'react';
import { getCookie } from './CookieManagement.js';

export const HttpRequest = (Toppic, Body) => {

    // console.log("cookie_token: ", cookie_token);

    const Request = new Promise((resolve, reject) => {
        fetch(`${process.env.PUBLIC_URL}/config.json`)
            .then(res => res.json())
            .then(config => {
                // console.log(config);

                const cookie_token = getCookie(config.cookie_key_session_token);
                const options = {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET, POST',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'sessiontoken': cookie_token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(Body) // body data type must match "Content-Type" header
                }

                let host = config.host;
                let port = (config.port && config.port !== '') ? (":" + config.port) : ("");

                fetch(host + port + "" + Toppic, options)
                    .then(response => response.json())
                    .then(data => { resolve(data) })
                    .catch((error) => { reject(error) });

            }).catch(err => { console.log(err, ' error') });
    });

    return Request;

}

export const HttpRequestForCSV = (Toppic, Body) => {

    // console.log("cookie_token: ", cookie_token);

    const Request = new Promise((resolve, reject) => {
        fetch(`${process.env.PUBLIC_URL}/config.json`)
            .then(res => res.json())
            .then(config => {
                // console.log(config);

                const cookie_token = getCookie(config.cookie_key_session_token);
                const options = {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET, POST',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'sessiontoken': cookie_token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(Body) // body data type must match "Content-Type" header
                }

                let host = config.host;
                let port = (config.port && config.port !== '') ? (":" + config.port) : ("");

                fetch(host + port + "" + Toppic, options)
                    .then(response => response.text())
                    .then(data => { resolve(data) })
                    .catch((error) => { reject(error) });

            }).catch(err => { console.log(err, ' error') });
    });

    return Request;

}

export const HttpRequestForCSVByURL = (url, Body) => {

    // console.log("cookie_token: ", cookie_token);

    const Request = new Promise((resolve, reject) => {
        fetch(`${process.env.PUBLIC_URL}/config.json`)
            .then(res => res.json())
            .then(config => {
                // console.log(config);

                const cookie_token = getCookie(config.cookie_key_session_token);
                const options = {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET, POST',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'sessiontoken': cookie_token
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(Body) // body data type must match "Content-Type" header
                }

                // let host = config.host;
                // let port = (config.port && config.port !== '') ? (":" + config.port) : ("");

                fetch(url, options)
                    .then(response => response.text())
                    .then(data => { resolve(data) })
                    .catch((error) => { reject(error) });

            }).catch(err => { console.log(err, ' error') });
    });

    return Request;

}